// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-accommodations-js": () => import("./../src/pages/accommodations.js" /* webpackChunkName: "component---src-pages-accommodations-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("./../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-registry-js": () => import("./../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-wedding-party-js": () => import("./../src/pages/wedding-party.js" /* webpackChunkName: "component---src-pages-wedding-party-js" */)
}

